<template>
  <div>
    <div id="chart">
      <h1>Top Popular flowers</h1>
      <div class="field">
        <label> Pick Top Number:</label>
        <input type="number" @change="onChange($event)" min="1" :max="max" />
      </div>
      <apexchart
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  props: {
    arraysLength: { type: Array },
    flowersNames: { type: Array },

    flowersData: { type: Object },
  },
  data() {
    return {
      topNumber: null,
      max: this.arraysLength.length,
      series: [
        {
          name: "popular",
          data: this.arraysLength,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: this.flowersNames,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    };
  },

  methods: {
    /**
     * checking user inpt on popular products chart
     */
    onChange(event) {
      let topNumber = event.target.value;
      let data = this.flowersData;
      let allData = [];
      for (let flower in data) {
        allData.push({
          label: flower,
          total: data[flower].length,
        });
      }
      /**
       * sorting popular products array acording to the user input
       */
      let maxItems = [...new Set(allData)]
        .sort((a, b) => b.total - a.total)
        .slice(0, topNumber);

      let series = [];
      let label = [];
      for (let i = 0; i < topNumber; i++) {
        series.push(maxItems[i].label);
        label.push(maxItems[i].total);
      }
      (this.series = [
        {
          name: "popular",
          data: label,
        },
      ]),
        (this.chartOptions = {
          xaxis: {
            categories: series,
            position: "bottom",
            axisBorder: {
              show: false,
            },
          },
        });
    },
  },
};
</script>
<style scoped>
input {
  padding: 6px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}
.field {
  display: inline-block;
  margin: 0 10px;
}
</style>