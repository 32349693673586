<template>
  <div>
    <div id="chart">
      <h1>Order Chart</h1>
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  props: {
    total_costArray: { type: Array },
    sumofItems: { type: Array },
    ordersDate: { type: Array },
  },
  data() {
    return {
      series: [
        {
          name: "Items",
          data: this.sumofItems,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: this.ordersDate,
        },
        annotations: {
          position: "front",
          xaxis: [
            {
              borderColor: "#111",
              label: {
                borderColor: "transparent",
                style: {
                  fontSize: "14px",
                  color: "#666",
                  background: "#e7e7e7",
                  padding: {
                    left: 15,
                    right: 15,
                    top: 10,
                    bottom: 12,
                  },
                },
                text: "now",
              },
            },
          ],
        },
        tooltip: {
          x: {
            format: "yyyy/MM/dd",
          },
        },
        yaxis: {
          title: {
            text: "Items",
          },
          min: 0,
        },
      },
    };
  },
/**
 * listen to the component data and run whenever they change a particular property
 */
  watch: {
    ordersDate: function (newordersDate) {
      (this.chartOptions = {
        xaxis: {
          type: "datetime",
          categories: newordersDate,
        },
      }),
        console.log("newordersDate", newordersDate);
    },

    sumofItems: function (newSumofItems) {
      (this.series = [
        {
          name: "Items",
          data: newSumofItems,
        },
      ]),
        console.log("newSumofItems", newSumofItems);
    },
  },
};
</script>

<style scoped>
</style>