<template>
  <div>
    <div id="chart">
      <h1>Total Cost and Orders Chart</h1>
      <apexchart
        type="line"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
export default {
  props: {
    total_costArray: { type: Array },
    sumofItems: { type: Array },
    ordersDate: { type: Array },
  },
  data() {
    return {
      series: [
        {
          name: "items",
          type: "column",
          data: this.sumofItems,
        },
        {
          name: " total cost",
          type: "area",
          data: this.total_costArray,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: this.ordersDate,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          labels: {
            format: " dd MMM  ",
          },
        },
        yaxis: {
          title: {
            text: "sales",
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " ";
              }
              return y;
            },
          },
        },
      },
    };
  },
  /**
   * listen to the component data and run whenever they change a particular property
   */
  watch: {
    total_costArray: function (newtotal_costArray) {
      (this.series = [
        {
          name: "items",
          type: "column",
          data: newtotal_costArray,
        },
        {
          name: " total cost",
          type: "area",
          data: newsumofItems,
        },
      ]),
        console.log("newtotal_costArray", newtotal_costArray);
    },
    sumofItems: function (newsumofItems) {
      (this.series = [
        {
          name: "items",
          type: "column",
          data: newtotal_costArray,
        },
        {
          name: " total cost",
          type: "area",
          data: newsumofItems,
        },
      ]),
        console.log("newsumofItems", newsumofItems);
    },
    ordersDate: function (newordersDate) {
      this.chartOptions = {
        labels: newordersDate,
      };
      console.log("newordersDate", newordersDate);
    },
  },
};
</script>

<style scoped>
</style>