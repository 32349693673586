<template>
  <div id="app">
    <div class="wrapper">
      <h1>Ifeza Flowers Chart  </h1>
      <div v-if="isLoading"><h1>Loading .....</h1></div>
      <ul class="item-list"></ul>
    </div>
    <footer>
      <form class="new-item-form" @submit.prevent="getOrderedItems">
        <div class="field">
          <label>Status:</label>
          <select v-model="slectedType" @change="onChange($event)">
            <option value="">All</option>
            <option value="pending">pending</option>
            <option value="expired">expired</option>
            <option value="completed">completed</option>
          </select>
        </div>
        <div class="field">
          <label> From:</label>
          <input type="date" v-model="fromDate" />
        </div>
        <div class="field">
          <label>To:</label>
          <input type="date" v-model="toDate" />
        </div>
        <button type="submit">Check</button>
      </form>
      <a href=""> <strong>Chart</strong> ifeza  flawer</a>
    </footer>
    <div class="chart-container">
     
      <OrderChart
        class="chart"
        v-if="sumofItems.length > 0"
        :total_costArray="total_costArray"
        :sumofItems="sumofItems"
        :ordersDate="ordersDate"
      />
      <MixedChart
        class="chart"
        v-if="sumofItems.length > 0"
        :total_costArray="total_costArray"
        :sumofItems="sumofItems"
        :ordersDate="ordersDate"
      />
       <ApexChart
        class="chart"
        v-if="flowersNames"
        :arraysLength="arraysLength"
        :flowersNames="flowersNames"
        :flowersData="flowersData"
      />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ApexChart from "./components/chart/ApexChart.vue";
import OrderChart from "./components/OrderChart.vue";
import MixedChart from "./components/chart/MixedChart.vue";
import moment from "moment";
export default {
  name: "App",
  components: {
    ApexChart,
    OrderChart,
    MixedChart,
  },
  data() {
    return {
      slectedType: "completed",
      fromDate: "2022-02-14",
      toDate: moment().format("yyy-MM-DD"),
      flowersNames: null,
      arraysLength: [],
      ordersDate: null,
      total_costArray: [],
      sumofItems: [],
      isLoading: true,
      flowersData:null,
    };
  },
  methods: {
    /**
     * updating charting when user pick new date
     */
    checkCahrt() {
      this.ordersDate = [];
      this.total_costArray = [];
      this.sumofItems = [];

      this.getOrderedItems();
      window.location.reload();


    },
    onChange(event) {
      console.log(event.target.value);
    },
    /**
     * method for getting all popular products
     */
    getPopularProducts() {
      axios
        .get(
          "https://test.ifeza.rwandabuildprogram.com/api/stats/popular-products"
        )
        .then((response) => {
          this.flowersNames = Object.keys(response.data);
          let flowers = response.data;

          this.flowersData=response.data;

      let flowersValue=[];
         
          Object.values(flowers).forEach((val) =>  {
         
              flowersValue.push(val);
      
              this.flowersValue=flowersValue;


            this.arraysLength.push(val.length);
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.isLoading = false;
          console.log("error", error);
        });
    },
    /**
     * method for fetching all orders based on certain date and status 
     */
    getOrderedItems() {
      
      axios
        .get(
          `https://test.ifeza.rwandabuildprogram.com/api/stats/orders?status=${this.slectedType}&from=${this.fromDate}&to=${this.toDate} `
        )
        .then((response) => {
          this.ordersDate = Object.keys(response.data);
          let orders = response.data;
          let totalCostSum = 0;
          const sumofItems_array = [];
          const sumofCost_array = [];
          Object.values(orders).forEach((val) => {
            let sumoftotalCost = val.reduce(function (previousItem, currentItem) {
              return previousItem + currentItem.total_cost;
            }, totalCostSum);          
            sumofCost_array.push(sumoftotalCost);
            let sumofItems = val.reduce(function (previousItem, currentItem) {
              return previousItem + currentItem.items;
            }, totalCostSum);
            sumofItems_array.push(sumofItems);
          });
          this.sumofItems = [];
          this.total_costArray = [];
          this.sumofItems = sumofItems_array;
          this.total_costArray = sumofCost_array;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
  },

  created() {
    this.getOrderedItems();
    this.getPopularProducts();
  },
};
</script>

<style>
#app {
  margin: 0;
  font-family: Roboto;
}
.chart-container {
  margin-top: 2rem;
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart {
  width: 80%;
}
.wrapper {
  max-width: 960px;
  margin: 0 auto;
}
h1 {
  margin: 40px auto;
  color: #41b883;
  text-align: center;
}
ul {
  padding: 0;
  list-style-type: none;
}
li {
  padding: 6px 10px;
  border: 1px solid #35495e;
  margin: 10px auto;
}
li h4 {
  color: #41b883;
  margin: 0;
  text-transform: capitalize;
}
li p {
  color: #333;
  margin: 8px 0 0;
}
footer {
  background: #eee;
  padding: 60px;
  margin-top: 60px;
}
form {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
label {
  display: block;
  font-weight: bold;
  font-size: 0.8em;
  color: #35495e;
  margin: 16px 0 6px;
}
input,
select {
  padding: 6px;
  border: 1px solid #35495e;
  border-radius: 4px;
}
.field {
  display: inline-block;
  margin: 0 10px;
}
button {
  color: white;
  border: 0;
  background: #41b883;
  padding: 6px;
  min-width: 80px;
  border-radius: 4px;
}
footer a {
  text-align: center;
  display: block;
  color: #35495e;
  margin-top: 40px;
  font-size: 0.7em;
}
</style>
